<template>
  <div class="myAboutDetail">
    <el-row :gutter="20" type="flex" class="el-row">
      <el-col :span="8" class="el-row8">
        <el-card>
          <div slot="header" class="clearfix">
            <span>预约处理进度</span>
          </div>
          <div class="wrapCardL">
            <!-- <el-timeline>
              <el-timeline-item
                v-for="(item, index) in detail.flowChart"
                :key="index"
                :timestamp="item.createTime"
                :type="item.done == true ? 'success' : ''"
                :icon="item.done == true ? 'el-icon-check' : ''"
                size="large"
                placement="top"
              >
                <el-card>
                  <h4>{{ item.reserveStatusDesc }}</h4>
                  <p>{{ item.remark }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline> -->
            <div class="noticeList">
              <div class="listWrap">
                <div v-for="(item, index) in detail.flowChart" :key="index" class="step">
                  <div class="stepContent">
                    <div class="stepTime">{{ item.createTime }}</div>
                    <h4 class="reserveStatusDesc">{{ item.reserveStatusName }}</h4>
                  </div>
                  <div class="circular" :class="{ active: item.done }"></div>
                  <div class="line"></div>
                  <p v-if="item.remark !== ''" class="remark">{{ item.remark }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span>讲师信息</span>
          </div>
          <div class="wrapCardR">
            <div class="letf_wrap">
              <div class="teacherImg">
                <img v-if="teacherDetail.teacherPics" :src="teacherDetail.teacherPics[0]" />
              </div>
              <div class="teacherName">{{ teacherDetail.teacherName }}</div>
              <div class="teacherDesc">职称：{{ teacherDetail.teacherRankStr }}</div>
            </div>
            <div class="right_wrap">
              <div>所属书院：{{ teacherDetail.organizationName }}</div>
              <div class="address">
                常驻地区：{{ teacherDetail.province }}
                <span v-if="teacherDetail.city !== ''">-</span> {{ teacherDetail.city }}
              </div>
              <div class="teacherType">
                分类：
                <span v-for="(item, index) in teacherDetail.teacherMajorsMap" :key="index">
                  {{ item.name }}
                </span>
              </div>
              <div class="expertise">
                <div class="left">专长：</div>
                <div class="right">
                  <span
                    v-for="(item, index) in teacherDetail.teacherTags"
                    :key="index"
                    :style="{ color: `${item.color}`, background: `${item.background}` }"
                  >
                    {{ item.tagName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>预约信息</span>
          </div>
          <div class="aboutNews">
            <div><span class="title">预约类型：</span>{{ detail.reserveType | filterType }}</div>
            <div><span class="title">预估费用：</span>{{ detail.costBudget | filterMoney }}</div>
            <div><span class="title">预估人数：</span>{{ detail.studentCount }}</div>
            <div><span class="title">联系人：</span>{{ detail.contactName }}</div>
            <div><span class="title">手机号码：</span>{{ detail.contactPhone }}</div>
            <div><span class="title">预约时间：</span>{{ detail.reserveTime }}</div>
            <div class="file">
              <div class="fileTitle">附件：</div>
              <div class="fileInfo">
                <div
                  v-for="(item, index) in detail.annexList"
                  :key="index"
                  class="fileInfoItem"
                  :title="item.annexName"
                >
                  <div class="fileDetail">
                    <img
                      v-if="
                        item.annexUrl.substring(item.annexUrl.lastIndexOf('.') + 1, item.length) ==
                          'png'
                      "
                      src="../../../assets/teacher/img.png"
                      alt=""
                    />
                    <img v-else src="../../../assets/teacher/file.png" class="fileImg" alt="" />
                    <div class="annexName">{{ item.annexName }}</div>
                  </div>
                  <div
                    v-if="
                      item.annexUrl.substring(item.annexUrl.lastIndexOf('.') + 1, item.length) ==
                        'png'
                    "
                    class="look"
                    @click="preview(item.annexUrl)"
                  >
                    预览
                  </div>
                  <div v-else class="look" @click="downLoad(item.annexUrl, item.annexName)">
                    下载
                  </div>
                </div>
              </div>
            </div>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import to from 'await-to'
import { getMyAboutDetail, geteTacherDetail } from '@/api/teacher'
export default {
  filters: {
    filterType(val) {
      if (val == 1) {
        return '线下授课'
      } else if (val == 2) {
        return '专题讲座'
      } else if (val == 3) {
        return '定制课程'
      } else {
        return '其他'
      }
    },
    filterMoney(money) {
      if (money == 1) {
        return '￥5000.00-￥10000.00'
      } else if (money == 2) {
        return '￥10000.00-￥15000.00'
      } else if (money == 3) {
        return '￥15000.00-￥20000.00'
      } else if (money == 4) {
        return '￥20000.00-￥30000.00'
      } else if (money == 5) {
        return '￥30000.00-￥40000.00'
      } else {
        return '￥50000.00-￥100000.00'
      }
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      teacherId: this.$route.query.teacherId,
      organizationId: this.$route.query.organizationId,
      detail: {},
      teacherDetail: {},
      dialogVisible: false,
      dialogImageUrl: '',
    }
  },
  created() {
    this.getMyAboutDetailData()
    this.geteTacherDetailData()
  },
  methods: {
    async getMyAboutDetailData() {
      const [res, err] = await to(getMyAboutDetail({ id: this.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data
    },
    async geteTacherDetailData() {
      const [res, err] = await to(
        geteTacherDetail({ id: this.teacherId, organizationId: this.organizationId }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.teacherDetail = res.data
    },
    preview(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    downLoad(url, name) {
      // window.location = url
      var strName = name.substring(0, name.indexOf('.'))
      this.downloads(url, strName)
    },
    downloads(url, filename) {
      var _this = this
      this.getBlob(url, function(blob) {
        _this.saveAs(blob, filename)
      })
    },
    getBlob(url, cb) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function() {
        if (xhr.status === 200) {
          cb(xhr.response)
        }
      }
      xhr.send()
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        var a = document.createElement('a')
        var body = document.querySelector('body')
        a.href = window.URL.createObjectURL(blob)
        a.download = filename
        a.style.display = 'none'
        body.appendChild(a)
        a.click()
        body.removeChild(a)
        window.URL.revokeObjectURL(a.href)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.myAboutDetail {
  ::v-deep .el-card__header span {
    font-size: 18px;
  }
  ::v-deep .el-card__header {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 41px;
      background: #ff7b33;
    }
  }
  .el-row8 {
    ::v-deep .el-card {
      height: 100%;
    }
  }
  .wrapCardL {
    margin-left: 5px;
    .noticeList {
      .listWrap {
        .step {
          position: relative;
          padding-bottom: 50px;
          &:last-child {
            padding-bottom: 0;
          }
          .stepContent {
            display: flex;
            position: relative;
            font-size: 14px;
            .stepTime {
              color: #909399;
              width: 133px;
            }
            .reserveStatusDesc {
              color: #333;
              margin-left: 66px;
            }
          }
          .circular {
            width: 10px;
            height: 10px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            position: absolute;
            left: 167px;
            top: 5px;
            box-sizing: border-box;
          }
          .active {
            background-color: #ffd0b7;
            border: 1px solid #ff7b33;
          }
          .line {
            position: absolute;
            top: 15px;
            left: 171px;
            width: 2px;
            height: 100%;
            background-color: #e2e2e2;
          }
          .remark {
            margin-top: 8px;
            color: #909399;
            font-size: 12px;
          }
          &:last-child .line {
            width: 0;
          }
        }
      }
    }
  }
  .wrapCardR {
    display: flex;
    .letf_wrap {
      text-align: center;
      .teacherName {
        font-size: 20px;
        color: #333;
        margin: 10px 0;
      }
      .teacherImg {
        width: 106px;
        height: 132px;
        margin: 0 auto;
      }
      .teacherDesc {
        font-size: 14px;
        color: #606266;
      }
    }
    .right_wrap {
      margin-left: 34px;
      div {
        font-size: 14px;
        color: #333;
      }
      .address {
        margin: 15px 0;
      }
      .teacherType {
        margin-bottom: 15px;
      }
      .expertise {
        display: flex;
        .right {
          flex: 1;
          span {
            margin: 0 5px 10px 5px;
            padding: 0 5px;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }
  .box-card {
    margin-top: 20px !important;
    .aboutNews {
      margin-left: 5px;
      div {
        font-size: 14px;
        color: #606266;
        margin: 18px 0;
        &:first-child {
          margin-top: 0 !important;
        }
        .title {
          width: 70px;
          text-align: right;
          display: inline-block;
          margin-right: 8px;
        }
      }
      .file {
        display: flex;
        ::v-deep .el-image {
          font-size: 30px;
          margin: 0;
        }
        .fileTitle {
          margin: 0;
          width: 70px;
          text-align: right;
          margin-right: 8px;
        }
        .fileInfo {
          display: flex;
          margin: 0;
          .fileInfoItem {
            text-align: center;
            margin-left: 16px;
            &:first-child {
              margin-left: 0;
            }
            .fileDetail {
              padding: 0 5px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 90px;
              height: 90px;
              border: 1px solid #e2e2e2;
              margin-bottom: 10px;
              .fileImg {
                width: 18px;
                height: 24px;
              }
              .annexName {
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-top: 10px;
                font-size: 12px;
                width: 100%;
              }
            }
            img {
              width: 22px;
              height: 20px;
            }
            .look {
              margin: 0;
              color: #ff7b33;
              cursor: pointer;
              font-size: 14px;
            }
          }
          div {
            margin: 0;
          }
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
